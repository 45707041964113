import { createSlice } from '@reduxjs/toolkit';
import { AppDispatch } from '../Store';
import { AxiosResponse, AxiosRequestConfig } from 'axios';
import * as assessmentRoute from '../route/assessmentRoute';
import { PaginationProp } from '../../utils/globalInterface/GlobalInterfaces';
import Alert from '../../utils/Alert/Alert';
import Swal from 'sweetalert2';
import { handleUpdateTransaction } from '../../utils/gridMethod/GridTransaction';
import { initialValues } from '../../pages/MyAccount/Stepper/InitialValues';
import { TakeDemogQues } from '../../pages/register/activatedStepper/ThirdStep';
import { getDemogByStudentId } from './metaDataReducer';
import { isEmpty } from 'lodash';

export interface AxiosError<T = any> extends Error {
  config: AxiosRequestConfig;
  code?: string;
  request?: object;
  data: object;
  response?: AxiosResponse<T>;
  isAxiosError: boolean;
  toJSON: () => object;
}

const initialState = {
  assessmentDiscipline: [],
  assessmentStatus: {},
  assessmentById: [],
  assessmentDetails: [],
  TaspaassessmentDetails: [],
  studentAssessmentData: [],
  AssessmentData: [],
  collegeName: [],
  assessmentStudent: [],
  assessmentQuestion: null,
  rFiles: [],
  tempStudentDetails: [],
  loading: false,
};

const getAssessmentDetail = () => async (dispatch: AppDispatch) => {
  return assessmentRoute
    .getAssessmentDetails()
    .then((res: AxiosResponse) => {
      if (res?.data) {
        dispatch(handleGetAssessment(res.data));
      }
    })
    .catch((err: AxiosError) => {});
};
const getTaspaAssessmentDetail = () => async (dispatch: AppDispatch) => {
  return assessmentRoute
    .getTaspaAssessmentDetails()
    .then((res: AxiosResponse) => {
      if (res?.data) {
        dispatch(handleGetTaspaAssessment(res.data));
      }
    })
    .catch((err: AxiosError) => {});
};

const addAssessmentData = (
  data: any,
  setOpen: any,
  getAssessmentByCourse: any,
  params: any,
  setIsDispatching: any,
  handleOpenAssessQue?: any,
  handleSetAssessSpecQuestion?: any
) => {
  return assessmentRoute
    .addCourseAssessment(data)
    .then((res: AxiosResponse) => {
      if (res?.data) {
        if (
          res?.data?.message === 'Assessment already exists for this course'
        ) {
          Alert.already({ title: 'Assessment', text: '' });
          setOpen(false);
        } else {
          Alert.add({ title: 'Assessment', text: '' });
          setOpen(false);
          setIsDispatching(false);
          if (handleOpenAssessQue) {
            handleSetAssessSpecQuestion(res?.data?.assessSpecQuestion);
            handleOpenAssessQue();
          }

          // getAssessmentByCourse(params);
          if (params) getAssessmentByCourse(params);
        }
      }
    })
    .catch((err: AxiosError) => {
      Alert.addError({ title: 'Assessment', text: '' });
      setOpen(false);
    });
};

const addCourseAssessSpecificQuestionData = (
  data: any,
  handleCloseAssessQue: any
) => {
  return assessmentRoute
    .addCourseAssessSpecificQuestion(data)
    .then((res: AxiosResponse) => {
      if (res?.data) {
        Alert.add({ title: 'Assessment specific question', text: '' });
        handleCloseAssessQue();
      }
    })
    .catch((err: AxiosError) => {
      Alert.addError({ title: 'Assessment specific question', text: '' });
      handleCloseAssessQue();
    });
};

const editCourseAssessmentData = (
  data: any,
  setOpen: any,
  getAssessmentByCourse: any,
  params: any,
  setInitialValue: any,
  setIsEdit: any,
  setIsDispatching: any,
  gridApi: any,
  initialValues: any,
  setLoading?: any,
  setRefresh?: any
) => {
  return assessmentRoute
    .editCourseAssessment(data)
    .then((res: AxiosResponse) => {
      if (res?.data) {
        Alert.update({ title: 'Assessment', text: '' });
        setOpen(false);
        setIsDispatching(false);
        setLoading(true);
        setRefresh(true);
        // getAssessmentByCourse(params);
        if (params) {
          setInitialValue({
            id: '',
            assessment_id: {
              official_name: '',
              assessment_id: '',
            },
            no_of_administrations: 2,
            created_by: '',
          });
          setIsEdit(false);
          getAssessmentByCourse(params);
        }
        // handleUpdateTransaction(gridApi, {
        //   ...initialValues,
        //   official_name: initialValues.assessment_id.official_name,
        //   no_of_administrations: res?.data?.course?.no_of_administrations,
        // });
      }
    })
    .catch((err: AxiosError) => {
      Alert.updateError({ title: 'Assessment', text: '' });
      setOpen(false);
    });
};

const getAssessmentData =
  (data: PaginationProp, params: any) => async (dispatch: AppDispatch) => {
    return assessmentRoute
      .getAssessment(data)
      .then((res: AxiosResponse) => {
        if (res?.data) {
          const { records, totCount } = res?.data;
          if (!records?.length) {
            params.api.showNoRowsOverlay();
          } else params.api.hideOverlay();

          return params.success({
            rowData: records,
            rowCount: totCount || 0,
          });
        }
      })
      .catch((err: AxiosError) => {
        params.fail();
      });
  };

const addAssessment =
  (
    data: any,
    dataSource: any,
    params: any,
    navigate: any,
    setButton: any,
    view: any,
    setView: any
  ) =>
  async (dispatch: AppDispatch) => {
    return assessmentRoute
      .addAssessment(data)
      .then((res: AxiosResponse) => {
        //
        if (res?.data) {
          if (res?.data?.message === 'Assessment code exists') {
            setButton(false);
            Alert.info({
              title: 'Assessment with same code already exists',
              text: '',
            });
          } else {
            navigate('/assessment');
            Alert.add({ title: 'Assessment', text: '' });
            if (view) {
              window.open(
                `${window.location.origin}/viewassessment?id=${res?.data?.assessmentQuestion?.id}`,
                '_blank'
              );
              setView(false);
            }
            localStorage.setItem('state', JSON.stringify(data));
          }
        }
      })
      .catch((err: AxiosError) => {
        Alert.addError({ title: 'Assessment', text: '' });
      });
  };

const editAssessmentData =
  (
    data: any,
    actionType: any,
    version: any,
    navigate: any,
    setButton: any,
    isLikertCategoryPage?: boolean,
    view?: any,
    setView?: any
  ) =>
  async (dispatch: AppDispatch) => {
    return assessmentRoute
      .editAssessment(data, data?.id)
      .then((res: AxiosResponse) => {
        if (res?.data) {
          if (res.data.message === 'Assessment code exists') {
            setButton(false);
            Alert.info({
              title: 'Assessment with same code already exists',
              text: '',
            });
          } else {
            if (isLikertCategoryPage) {
              Alert.success({
                title: 'Assessment updated successfully',
                text: '',
              });
              localStorage.setItem('state', JSON.stringify(data));
            } else if (data.is_published) {
              navigate('/assessment');
              Alert.success({
                title: 'Assessment published successfully',
                text: '',
              });
              localStorage.setItem('state', JSON.stringify(data));
            } else {
              const assessmentData = {
                state: {
                  state: {
                    value: {
                      id: data.id,
                      discipline: {
                        id: data.discipline.id,
                        name: data.discipline.name,
                      },
                      officialAssessmentName: data.officialAssessmentName,
                      publicAssessmentName: data.publicAssessmentName,
                      assessmentCode: data.assessmentCode,
                      publicCode: data.publicCode,
                      under_development: data.under_development,
                      reference_image: data.reference_image,
                      image_description: data.image_description,
                      minimum_duration: data.minimum_duration,
                      questionType: data.questionType,
                      optionType: data.optionType,
                      key: data.key,
                      filter: data?.filter,
                      likert: data.likert,
                      question: data.question,
                      question_id: data.question_id,
                    },
                    action: actionType === 'Update' ? 'Update' : 'Version',
                    version: actionType === 'Update' ? false : true,
                  },
                },
                url: '/question',
              };
              dispatch(getAssessmentQuestion(assessmentData, navigate));
              setButton(false);
              if (view) {
                window.open(
                  `${window.location.origin}/viewassessment?id=${data.question_id}`,
                  '_blank'
                );
                setView(false);
              }

              localStorage.setItem('state', JSON.stringify(data));
              Alert.update({ title: 'Assessment', text: '' });
            }
          }
        }
      })
      .catch((err: AxiosError) => {
        Alert.updateError({ title: 'Assessment', text: '' });
      });
  };

const getAssessmentById = (data: any) => async (dispatch: AppDispatch) => {
  return assessmentRoute
    .getAssessmentById(data)
    .then((res: AxiosResponse) => {
      if (res?.data) {
        dispatch(handleAssessmentById(res.data));
      }
    })
    .catch((err: AxiosError) => err);
};

const deleteAssessment =
  (data: any, gridApi: any) => async (dispatch: AppDispatch) => {
    return assessmentRoute
      .deleteAssessment(data.id)
      .then((res: AxiosResponse) => {
        if (res?.data) {
          if (res.data.message === 'Assessment is already used in course') {
            Alert.info({
              title: 'Assessment is already used in course',
              text: '',
            });
          } else {
            try {
              gridApi.refreshServerSideStore({ purge: true });
            } catch (grdErr) {
              //
            }
            Alert.delete({ title: 'Assessment', text: '' });
          }
        }
      })
      .catch((err: AxiosError) =>
        Alert.deleteError({ title: 'Assessment', text: '' })
      );
  };

const takeAssessment =
  (data: any, navigate: any, timeLimit?: boolean, autoSave?: boolean) =>
  async (dispatch: AppDispatch) => {
    const {
      course_student_id,
      course_assessment_id,
      administration_no,
      assessmentCompleted,
    } = data; // Destructure needed variables from data

    const navigateAfterConsent = (updatedDemographics: TakeDemogQues) => {
      data.demographics = updatedDemographics;
      if (isEmpty(updatedDemographics)) {
        //no demographics question on this course or they don't want the students to take demographics
        navigateAfterDemoQuest();
      } else {
        if (data?.login) {
          navigate('/student/demographicsquestionnaire', {
            state: data,
          });
        } else {
          navigate('/demographicsquestionnaire', {
            state: data,
          });
        }
      }
    };
    const navigateAfterAssessment = () => {
      navigate(data.login ? '/student/consent-form' : '/consent-form', {
        state: data,
      });
    };

    const navigateAfterDemoQuest = () => {
      navigate('/assessment/success', {
        state: { name: 'demographics', studentAccountData: data },
      });
    };

    return assessmentRoute
      .takeassessment(data)
      .then((res: AxiosResponse) => {
        if (res?.data) {
          if (res.data.message === 'Already taken the test') {
            Alert.info({ title: 'Assessment already taken', text: '' }).then(
              () => {
                navigateAfterAssessment();
              }
            );
          } else if (res.data.message === 'Assessment End time crossed') {
            Alert.info({ title: 'Assessment Deadline crossed', text: '' }).then(
              () => navigate('/login')
            );
          } else if (res.data.message === 'Assessment saved successfully') {
            Alert.success({ title: 'Assessment Saved', text: '' }).then(() => {
              navigateAfterAssessment();
            });
          } else if (res.data.message === 'Assessment deleted') {
            Alert.info({ title: 'Assessment deleted', text: '' }).then(() =>
              navigate('/login')
            );
          } else if (res.data.message === 'Assessment is not active') {
            Alert.info({ title: 'Assessment is not active', text: '' }).then(
              () => navigate('/login')
            );
          } else if (res.data.message === 'Assessment not started') {
            Alert.info({ title: 'Assessment not started', text: '' }).then(() =>
              navigate('/login')
            );
          } else if (timeLimit) {
            Alert.info({
              title: 'Time limit reached',
              text: 'Your answers are captured successfully',
            }).then((result: any) => {
              if (result.isConfirmed) {
                navigateAfterAssessment();
              }
            });
          } else if (autoSave) {
          } else if (!data?.test_status && data?.data?.is_activated !== true) {
            Swal.fire({
              text: 'Your assessment has been saved. You can resume by clicking on the link again in the email, or you can also click the account creation button below to create an account.',
              icon: 'success',
              showCancelButton: true,
              showCloseButton: true,
              confirmButtonText: 'No thanks',
              cancelButtonText: 'Account Creation',
            }).then((result) => {
              if (result.isConfirmed) {
                // User clicked 'No thanks' button
                navigate('/login');
              } else if (result.dismiss === Swal.DismissReason.cancel) {
                // User clicked 'Account creation' button
                navigate(
                  `/assessment/student/${course_student_id}/${course_assessment_id}/${administration_no}/signup`
                );
              }
            });
          } else if (!data?.test_status && data?.data?.is_activated === true) {
            /* when assessment saved using save button */
            Alert.success({ title: 'Assessment Saved', text: '' }).then(() =>
              navigate('/login')
            );
          } else if (res.data.message === 'Student Demographics Saved') {
            //when demographics saved
            Alert.success({ title: 'Demographics Submitted', text: '' }).then(
              () => {
                navigateAfterDemoQuest();
              }
            );
          } else if (res.data.message === 'Student Consent Saved') {
            // navigate('/assessment/success', {
            //   state: { name: 'demographics', studentAccountData: data },
            // });
            dispatch(
              getDemogByStudentId(data?.course_student_id, navigateAfterConsent)
            );
          } else if (assessmentCompleted === 'assessmentSaved') {
            /* when consent form submitted */
            Alert.success({ title: 'Assessment Completed', text: '' }).then(
              () => {
                // navigate('/assessment/success', {
                //   state: { name: 'demographics', studentAccountData: data },
                // })
                navigateAfterAssessment();
              }
            );
          } else {
            data.assessmentCompleted = 'assessmentSaved';

            if (data?.test_assessment_ids?.length > 0) {
              navigate(data.login ? '/student/test-items' : '/test-items', {
                state: data,
              });
            } else {
              navigateAfterAssessment();
            }
          }
        }
      })
      .catch((err: AxiosError) => err);
  };

const checkAssessmentStatus =
  (
    data: any,
    navigate: any,
    afterGettingAssessmentStatus?: any,
    setRefImage?: any,
    SetImageDescription?: any
  ) =>
  async (dispatch: AppDispatch) => {
    return assessmentRoute
      .checkAssessmentStatus(data)
      .then((res: AxiosResponse) => {
        if (res?.data) {
          const image = res.data.data.reference_image;
          const image_description = res.data.data.image_description;
          if (setRefImage) {
            setRefImage(image);
          }
          if (SetImageDescription) {
            SetImageDescription(image_description);
          }
          if (res.data.message === 'Assessment Not Found') {
            Alert.info({ title: 'Assessment Not Found', text: '' }).then(() =>
              navigate('/login')
            );
          } else {
            dispatch(handleAssessmentStatus(res.data?.data));
            if (afterGettingAssessmentStatus) {
              afterGettingAssessmentStatus(res.data?.data);
            }
          }
        }
      })
      .catch((err: AxiosError) => {
        Alert.info({ title: 'Assessment Not Found', text: '' }).then(() =>
          navigate('/login')
        );
      });
  };

const getStudentAssessmentData =
  (data: any, navigate: any) => async (dispatch: AppDispatch) => {
    return assessmentRoute
      .getStudentAssessmentData(data)
      .then((res: AxiosResponse) => {
        if (res?.data) {
          if (res.data.message === 'Assessment Not Found') {
            Alert.info({ title: 'Assessment Not Found', text: '' }).then(() =>
              navigate('/login')
            );
          } else {
            dispatch(handleStudentAssessmentData(res.data?.data));
          }
        }
      })
      .catch((err: AxiosError) => {
        Alert.info({ title: 'Assessment Not Found', text: '' }).then(() =>
          navigate('/login')
        );
      });
  };

const getAssessmentStudent =
  (
    data: any,
    navigate: any,
    afterGettingStudentStatus?: any,
    assessmentStatus?: any
  ) =>
  async (dispatch: AppDispatch) => {
    return assessmentRoute
      .getAssessmentStudent(data)
      .then((res: AxiosResponse) => {
        if (res?.data) {
          dispatch(handleAssessmentStudent(res?.data?.data));
          if (afterGettingStudentStatus && assessmentStatus) {
            afterGettingStudentStatus(assessmentStatus, res?.data?.data);
          }
        }
      })
      .catch((err: AxiosError) => {
        Alert.info({ title: 'Assessment Not Found', text: '' }).then(() =>
          navigate('/login')
        );
      });
  };

const addAssessmentDiscipline =
  (data: any, openForm?: any, setOpenForm?: any) =>
  async (dispatch: AppDispatch) => {
    return assessmentRoute
      .addAssessmentDiscipline(data)
      .then((res: AxiosResponse) => {
        if (res?.data) {
          if (res?.data?.message === 'Discipline code exists') {
            Alert.already({ title: 'Assessment Discipline', text: '' });
          } else {
            dispatch(getAssessmentDiscipline());
            Alert.add({ title: 'Assessment Discipline', text: '' });
            openForm.callback('discipline', {
              name: res?.data?.data?.name,
              id: res?.data?.data?.id,
            });

            setOpenForm({ ...openForm, isOpen: false });
          }
        }
      })
      .catch((err: AxiosError) => {
        Alert.addError({ title: 'Assessment Discipline', text: '' });
      });
  };

const getAssessmentDiscipline = () => async (dispatch: AppDispatch) => {
  return assessmentRoute
    .getAssessmentDiscipline()
    .then((res: AxiosResponse) => {
      if (res?.data) {
        dispatch(handleAssessmentDiscipline(res?.data?.data));
      }
    })
    .catch((err: AxiosError) => err);
};

const searchAssessment = (data: string, params: any) => async () => {
  return assessmentRoute
    .searchAssessment(data)
    .then((res: AxiosResponse) => {
      if (res?.data) {
        const { records, totCount } = res?.data;

        if (!records?.length) {
          params.api.showNoRowsOverlay();
        } else params.api.hideOverlay();

        params.success({
          rowData: records,
          rowCount: totCount || 0,
        });
      }
    })
    .catch((err: AxiosError) => {
      params.fail();
    });
};

const addAssessmentVersion =
  (
    data: any,
    dataSource: any,
    params: any,
    navigate: any,
    setButton: any,
    view: any,
    setView: any
  ) =>
  async (dispatch: AppDispatch) => {
    return assessmentRoute
      .addAssessmentVersion(data)
      .then((res: AxiosResponse) => {
        setButton(false);
        if (res?.data) {
          if (data?.is_published == 'true' || data?.is_published == true) {
            navigate('/assessment');
          } else {
            const responseData = res?.data?.data;
            const assessmentData = {
              state: {
                state: {
                  value: {
                    id: data.id,
                    discipline: {
                      id: data.discipline.id,
                      name: data.discipline.name,
                    },
                    officialAssessmentName: data.officialAssessmentName,
                    publicAssessmentName: data.publicAssessmentName,
                    assessmentCode: data.assessmentCode,
                    publicCode: data.publicCode,
                    minimum_duration: data.minimum_duration,
                    questionType: data.questionType,
                    optionType: data.optionType,
                    key: data.key,
                    filter: data?.filter,
                    likert: data.likert,
                    question: data.question,
                    question_id: responseData.question_id,
                  },
                  action: 'Update',
                },
              },
              url: '/question',
            };
            dispatch(getAssessmentQuestion(assessmentData, navigate));
            setButton(false);
            if (view) {
              window.open(
                `${window.location.origin}/viewassessment?id=${responseData.question_id}`,
                '_blank'
              );
              setView(false);
            }
          }
          Alert.add({ title: 'New Version', text: '' });
        }
      })
      .catch((err: AxiosError) => {
        Alert.addError({ title: 'New Version', text: '' });
      });
  };

const updatePublishStatus =
  (data: any, gridApi: any | undefined, rowData: any) =>
  async (dispatch: AppDispatch) => {
    return assessmentRoute
      .updatePublishStatus(data)
      .then((res: AxiosResponse) => {
        if (res?.data) {
          if (res.data.message === 'Assessment is already used in course') {
            Alert.info({
              title: 'Assessment is already used in course',
              text: '',
            });
          } else {
            try {
              gridApi.refreshServerSideStore({ purge: true });
            } catch (grdErr) {
              //
            }

            // Alert.delete({ title: 'Assessment', text: '' });
            Alert.success({ title: 'Unpublished assessment', text: '' });
          }
        }
      })
      .catch((err: AxiosError) => {
        Alert.error({ title: 'Unpublished assessment failed!', text: '' });
      });
  };

const updateAdaptivePublishStatus =
  (data: any, gridApi: any | undefined, rowData: any) =>
  async (dispatch: AppDispatch) => {
    return assessmentRoute
      .updateAdaptivePublishStatus(data)
      .then((res: AxiosResponse) => {
        if (res?.data) {
          if (res.data.message === 'Assessment is already used in course') {
            Alert.info({
              title: 'Assessment is already used in course',
              text: '',
            });
          } else {
            try {
              gridApi.refreshServerSideStore({ purge: true });
            } catch (grdErr) {
              //
            }

            // Alert.delete({ title: 'Assessment', text: '' });
            Alert.success({
              title: `${
                data.adaptive_published ? 'Published' : 'Unpublished'
              }  assessment`,
              text: '',
            });
          }
        }
      })
      .catch((err: AxiosError) => {
        Alert.error({
          title: `${
            data.adaptive_published ? 'Published' : 'Unpublished'
          }  assessment failed!`,
          text: '',
        });
      });
  };

const getAssessmentQuestion =
  (data: any, navigate: any, set?: any) => async (dispatch: AppDispatch) => {
    return assessmentRoute
      .getAssessmentQuestion(data.state.state.value.question_id)
      .then((res: AxiosResponse) => {
        if (res?.data) {
          data.state.state.value.question = res?.data?.data;
          data.state.state.value.assessSpecQuestion =
            res?.data?.assessmentSpecQuestion;

          data.state.state.value.discipline = res?.data?.discipline;
          data.state.state.value.reports = res?.data?.reports;
          data.state.state.value.reference_image = res?.data?.refImage;
          data.state.state.value.image_description =
            res?.data?.image_description;
          navigate(data.url, data.state);

          if (
            set &&
            res?.data?.discipline !== data.state.state.value.discipline
          ) {
            set((prev: any) => ({
              ...prev,
              discipline: res?.data?.discipline,
            }));
          }
        }
      })
      .catch((err: AxiosError) => err);
  };

const viewAssessment =
  (
    data: any,
    setData: any,
    setView: any,
    setRefImage: any,
    SetImageDescription: any
  ) =>
  async (dispatch: AppDispatch) => {
    return assessmentRoute
      .getAssessmentQuestion(data.question_id)
      .then((res: AxiosResponse) => {
        if (res?.data) {
          setData({ ...data, questions: res?.data?.data });
          setView(false);
          setRefImage(res?.data?.refImage);
          SetImageDescription(res?.data?.image_description);
        }
      })
      .catch((err: AxiosError) => {
        setView(false);
        return err;
      });
  };

const addAdaptiveAssessment =
  (data: any, navigate: any) => async (dispatch: AppDispatch) => {
    return assessmentRoute
      .addAdaptiveAssessment(data)
      .then((res: AxiosResponse) => {
        if (res?.data) {
          if (res?.data?.errorMessage === 'Assessment code exists') {
            // setButton(false);
            Alert.info({
              title: 'Assessment with same code already exists',
              text: '',
            });
          } else {
            data.id = res?.data?.data?.id;
            navigate('/assessment/adaptive', {
              state: {
                value: data,
                action: 'Create',
              },
            });
            Alert.add({ title: 'Assessment', text: '' });
          }
        }
      })
      .catch((err: AxiosError) => {
        Alert.addError({ title: 'Assessment', text: '' });
      });
  };

const editAdaptiveAssessment =
  (data: any, navigate: any, setLoading: any) =>
  async (dispatch: AppDispatch) => {
    return assessmentRoute
      .editAdaptiveAssessment(data, data.id)
      .then((res: AxiosResponse) => {
        if (res?.data) {
          if (res?.data?.errorMessage === 'Assessment code exists') {
            // setButton(false);
            Alert.info({
              title: 'Assessment with same code already exists',
              text: '',
            });
          } else {
            let updatedData;
            try {
              updatedData = {
                ...data,
                contentModules: res.data?.data.content_modules,
                contentModulesTime: res.data?.data.content_modules_time,
                contentModulesDescript: res.data?.data.content_modules_descript,
                learningObjectives: res.data?.data.learning_objectives,
                learningObjectivesTime: res.data?.data.learning_objectives_time,
                learningObjectivesDescript:
                  res.data?.data.learning_objectives_descript,
              };
            } catch (err) {
              console.log(err);
            }
            setLoading(false);
            navigate('/assessment/adaptive', {
              state: {
                value: updatedData,
                action: 'Create',
              },
            });
            Alert.update({ title: 'Assessment', text: '' });
          }
        }
      })
      .catch((err: AxiosError) => {
        setLoading(false);
        Alert.updateError({ title: 'Assessment', text: '' });
      });
  };

// const getAdaptiveAssessment =
//   (data: any, set: any) => async (dispatch: AppDispatch) => {
//     try {
//       const res: AxiosResponse = await assessmentRoute.getAdaptiveAssessment(
//         data
//       );

//       if (res?.data) {
//         const data = res.data.data;
//         const alteredData = data.map((item: any) => {
//           // Check if 'learning_objectives' and 'content_modules' are arrays
//           if (Array.isArray(item.learning_objectives)) {
//             item.learning_objectives.map((obj: any) => {
//               const key = Object.keys(obj)[0];
//               const value = obj[key];
//               item['learning_objectives_obj'] = {};
//               item['learning_objectives_obj'][key] = value;
//             });
//           }

//           if (Array.isArray(item.content_modules)) {
//             item.content_modules.map((obj: any) => {
//               const key = Object.keys(obj)[0];
//               const value = obj[key];
//               item['content_modules_obj'] = {};
//               item['content_modules_obj'][key] = value;
//             });
//           }

//           return item;
//         });

//         set(alteredData); // Use 'alteredData' instead of 'res.data.data'
//       }
//     } catch (err: any) {
//       // Handle error here, if needed
//
//     }
//   };

const getAdaptiveAssessment =
  (data: any, set: any, setForm: any) => async (dispatch: AppDispatch) => {
    try {
      const res: AxiosResponse = await assessmentRoute.getAdaptiveAssessment(
        data
      );

      if (res?.data) {
        const data = res.data.data;
        if (setForm) {
          setForm(() => res.data.assessment_data);
        }
        const alteredData = data.map((item: any) => {
          // Check if 'learning_objectives' and 'content_modules' are arrays
          if (Array.isArray(item.learning_objectives)) {
            // Convert 'learning_objectives' array to an object
            item['learning_objectives_obj'] = {};
            item.learning_objectives.forEach((obj: any) => {
              const key = Object.keys(obj)[0];
              const value = obj[key];
              item['learning_objectives_obj'][key] = String(value);
            });
            // Remove the original 'learning_objectives' array from the item
            delete item.learning_objectives;
          }

          if (Array.isArray(item.content_modules)) {
            // Convert 'content_modules' array to an object
            item['content_modules_obj'] = {};
            item.content_modules.forEach((obj: any) => {
              const key = Object.keys(obj)[0];
              const value = obj[key];
              item['content_modules_obj'][key] = String(value);
            });
            // Remove the original 'content_modules' array from the item
            delete item.content_modules;
          }

          return item;
        });

        const re = alteredData.map((d: any) => ({
          ...d,
          ...d.content_modules_obj,
          ...d.learning_objectives_obj,
        }));
        set(re); // Use 'alteredData' instead of 'res.data.data'
      }
    } catch (err: any) {
      // Handle error here, if needed
      //
    }
  };

const addAdaptiveItem =
  (data: any, set: any, navigate: any, setData: any, setButton: any) =>
  async (dispatch: AppDispatch) => {
    return assessmentRoute
      .addAdaptiveItem(data)
      .then((res: AxiosResponse) => {
        if (res?.data) {
          if (res?.data?.message === 'Question label already exists') {
            Alert.already({
              title: `Question label "${data?.question?.questionLabel}"`,
              text: '',
            });
          } else if (data.action === 'Edit') {
            Alert.update({ title: 'Assessment Item', text: '' });
            set(false);
          } else {
            Alert.add({ title: 'Assessment Item', text: '' });
            set(false);
          }

          dispatch(getAdaptiveAssessment(data.assessment_id, setData, ''));
        }
      })
      .catch((err: AxiosError) => {
        if (data.action === 'Edit') {
          Alert.updateError({ title: 'Assessment Item', text: '' });
        } else {
          Alert.addError({ title: 'Assessment Item', text: '' });
        }
      })
      .finally(() => {
        setButton(false);
      });
  };

const editAdaptiveItem =
  (data: any, navigate: any) => async (dispatch: AppDispatch) => {
    return assessmentRoute
      .editAdaptiveItem(data, data.id)
      .then((res: AxiosResponse) => {
        if (res?.data) {
          if (res?.data?.errorMessage === 'Assessment code exists') {
            // setButton(false);
            Alert.info({
              title: 'Assessment with same code already exists',
              text: '',
            });
          } else {
            navigate('/assessment/adaptive', {
              state: {
                value: data,
                action: 'Create',
              },
            });
            Alert.add({ title: 'Assessment', text: '' });
          }
        }
      })
      .catch((err: AxiosError) => {
        Alert.addError({ title: 'Assessment', text: '' });
      });
  };

const deleteAdaptiveItem =
  (data: any, navigate: any, state: any) => async (dispatch: AppDispatch) => {
    return assessmentRoute
      .deleteAdaptiveItem(data)
      .then((res: AxiosResponse) => {
        if (res?.data) {
          Alert.delete({ title: 'Assessment Item', text: '' });

          navigate('/assessment/adaptive', {
            state: state,
          });
        }
      })
      .catch((err: AxiosError) => {
        Alert.deleteError({ title: 'Assessment Item', text: '' });
      });
  };

const fileUpload =
  (data: any, navigate: any, set: any, setIsUploading: any) =>
  async (dispatch: AppDispatch) => {
    return assessmentRoute
      .fileUpload(data)
      .then((res: AxiosResponse) => {
        if (res?.data) {
          if (res?.data?.alreadyExistQuestionLabel.length > 0) {
            Alert.already({
              title: `Question label`,
              text: `"${res?.data?.alreadyExistQuestionLabel.join(
                ', '
              )}" but others are added successfully`,
            });
          } else {
            Alert.add({ title: 'Assessment Item', text: '' });
            dispatch(getAdaptiveAssessment(data.id, set, ''));
          }
        }
      })
      .catch((err: AxiosError) => {
        Alert.addError({ title: 'Assessment Item', text: '' });
      })
      .finally(() => setIsUploading(false));
  };

const getAdaptiveAssessmentQuestion =
  (
    data: any,
    setQue?: any,
    setVal?: any,
    option?: any,
    firstQuestion?: any,
    course_student_id?: any,
    administration_no?: any,
    setcurrentQuestion?: any,
    id?: any,
    action?: any,
    navigate?: any,
    question?: any,
    duration?: any,
    setActiveStep?: any
  ) =>
  async (dispatch: AppDispatch) => {
    try {
      const res = await assessmentRoute.getAdaptiveAssessmentQuestion({
        id: data,
        // questionId: id,
        option: option ? option : '',
        firstQuestion: firstQuestion,
        course_student_id: course_student_id ? course_student_id : '',
        administration_no: administration_no ? administration_no : '',
        questionId: id ? id : '',
        action: action ? action : '',
        question: question ? question : '',
        duration: duration ? duration : 0,
      });

      if (res?.data) {
        if (setQue) {
          setcurrentQuestion((prev: any) => {
            let fetchedQuestion: any = [];

            setVal((pr: any) => {
              fetchedQuestion = res.data.data.question_data.data;

              if (fetchedQuestion.length > 0) {
                fetchedQuestion[0].duration = 0;
                pr.question_data.data.push(...fetchedQuestion);
              }

              return pr;
            });

            if (fetchedQuestion.length > 0) {
              return fetchedQuestion[0];
            } else {
              setActiveStep((prev: any) => prev - 1);
              Swal.fire({
                title: `No more questions are available. Please click the 'Next' button to submit the assessment.`,
                icon: 'info',
              });
              return question[question.length - 1];
            }
          });

          dispatch(
            getStudentAssessmentData(
              {
                course_assessment_id: data,
                course_student_id: course_student_id,
                administration_no: administration_no,
              },
              navigate
            )
          );
        } else {
          dispatch(handleAssessmentById(res.data));
        }
      }
    } catch (error) {
      // console.error('Error in getAdaptiveAssessmentQuestion:', error);
    }
  };

const updateSession =
  (sessionStatus?: any) => async (dispatch: AppDispatch) => {
    try {
      const res = await assessmentRoute.updateSession(sessionStatus);
      if (res?.data) {
        //
      }
    } catch (error) {
      // console.error('Error in updateSession:', error);
    }
  };

const getAdaptiveAssessmentListWithTestItems =
  (set: any) => async (dispatch: AppDispatch) => {
    return assessmentRoute
      .getAdaptiveAssessmentListWithTestItems()
      .then((res: AxiosResponse) => {
        if (res?.data) {
          set(res.data.data);
        }
      })
      .catch((err: AxiosError) => {});
  };

const getAssessmentReports = (set: any) => async (dispatch: AppDispatch) => {
  return assessmentRoute
    .getAssessmentReports()
    .then((res: AxiosResponse) => {
      if (res?.data) {
        set(res.data.files);
      }
    })
    .catch((err: AxiosError) => {});
};

const getSelectedAssessmentReports =
  (id: string, set: any) => async (dispatch: AppDispatch) => {
    return assessmentRoute
      .getSelectedAssessmentReports(id)
      .then((res: AxiosResponse) => {
        if (res?.data) {
          set(res.data.files);
        }
      })
      .catch((err: AxiosError) => {});
  };

const getSelectedAdaptiveAssessmentListWithTestItems =
  (id: any, set: any, setInitialValueOfTestItems?: any) =>
  async (dispatch: AppDispatch) => {
    return assessmentRoute
      .getSelectedAdaptiveTestItems(id)
      .then((res: AxiosResponse) => {
        if (res?.data) {
          set(res?.data?.data?.length > 0 ? res.data.data : []);
          if (setInitialValueOfTestItems) {
            setInitialValueOfTestItems(
              res?.data?.data?.length > 0 ? res.data.data : []
            );
          }
        }
      })
      .catch((err: AxiosError) => {});
  };

const saveAdaptiveAssessmentListWithTestItems =
  (data: any, id: any, set: any, setName: any) =>
  async (dispatch: AppDispatch) => {
    return assessmentRoute
      .saveAdaptiveAssessmentListWithTestItems(data, id)
      .then((res: AxiosResponse) => {
        if (res?.data) {
          set(false);
          dispatch(getSelectedAdaptiveAssessmentListWithTestItems(id, setName));
          Alert.add({ title: 'Assessment Link with Adaptive Item', text: '' });
        }
      })
      .catch((err: AxiosError) => {
        Alert.addError({
          title: 'Assessment Link with Adaptive Item',
          text: '',
        });
      });
  };

const getAdaptiveTestItems =
  (id: any, set: any, setCurrent: any) => async (dispatch: AppDispatch) => {
    return assessmentRoute
      .getAdaptiveTestItems(id)
      .then((res: AxiosResponse) => {
        if (res?.data) {
          // set(false);
          set(res.data.data);
          setCurrent(res.data.data[0]);
        }
      })
      .catch((err: AxiosError) => {});
  };

const takeTestItems =
  (data: any, navigate: any) => async (dispatch: AppDispatch) => {
    const navigateAfterAssessment = () => {
      navigate(data.login ? '/student/consent-form' : '/consent-form', {
        state: data,
      });
    };
    return assessmentRoute
      .takeTestItems(data)
      .then((res: AxiosResponse) => {
        if (res?.data) {
          if (res?.data?.message === 'Already taken the test') {
            Alert.info({
              title: 'Assessment Test Items already taken',
              text: '',
            });
            navigateAfterAssessment();
          } else {
            Alert.success({
              title: 'Assessment Test Items submitted successfully',
              text: '',
            });
            navigateAfterAssessment();
          }
        }
      })
      .catch((err: AxiosError) => {});
  };

const getRFiles = () => async (dispatch: AppDispatch) => {
  return assessmentRoute
    .getRFiles()
    .then((res: AxiosResponse) => {
      if (res?.data) {
        dispatch(handleRFiles(res.data));
      }
    })
    .catch((err: AxiosError) => {});
};

const getAdaptiveAssessmentQuestionById =
  (data: any, setQuestion: any, setInitialValue: any, setOpen: any) =>
  async (dispatch: AppDispatch) => {
    return assessmentRoute
      .getAdaptiveAssessmentQuestionById(data.id)
      .then((res: AxiosResponse) => {
        if (res?.data?.data) {
          const question = res.data.data;

          setInitialValue((prev: any) => {
            return {
              item_code: data.item_code,
              id: data.id,
              item_id: data.item_id,
              assessment_id: data.assessment_id,
              status: data.status,
              slip: data.slip,
              defaultAnswer: question?.defaultAnswer, // Assuming item_id is the key value
              guess: data.guess,
              discrimination: data.discrimination,
              difficulty: data.difficulty,
              guessing: data.guessing,
              content_modules: {},
              learning_objectives: {},
              content_modules_structure: data.content_modules_structure,
              learning_objectives_structure: data.learning_objectives_structure,
              content_modules_obj: data.content_modules_obj,
              learning_objectives_obj: data.learning_objectives_obj,
              questionLabel: question.questionLabel,
              questionContent: question.questionContent,
              responseOptions:
                question?.responseOptions?.length > 0
                  ? res.data.data.responseOptions.map((option: any) => ({
                      optionLabel: option.optionLabel,
                      optionContent: option.optionContent,
                      optionType:
                        question?.responseType === 'Single' &&
                        option.optionType === 'none'
                          ? undefined
                          : option.optionType,
                    }))
                  : [],
            };
          });

          setQuestion({
            ...question,
            questionLabel: question.questionLabel ? question.questionLabel : '',
            questionContent: question.questionContent
              ? question.questionContent
              : '',
            responseType: question.responseType
              ? question.responseType
              : 'Single',
            optionOrientation: question.optionOrientation
              ? question.optionOrientation
              : 'Vertical',
            defaultAnswer: question?.defaultAnswer, // Assuming item_id is the key value
            responseOptions:
              question?.responseOptions?.length > 0
                ? question.responseOptions.map((option: any) => ({
                    optionLabel: option.optionLabel,
                    optionContent: option.optionContent,
                    optionType:
                      question?.responseType === 'Single' &&
                      option.optionType === 'none'
                        ? undefined
                        : option.optionType,
                  }))
                : [],
          });
          setOpen(true);
        }
      })
      .catch((err: AxiosError) => err);
  };
const AssessmentSlice = createSlice({
  name: 'AssessmentSlice',
  initialState,
  reducers: {
    handleAssessmentDiscipline: (state, action) => {
      state.assessmentDiscipline = action.payload;
    },
    handleAssessmentStudent: (state, action) => {
      state.assessmentStudent = action.payload;
    },
    handleAssessmentStatus: (state, action) => {
      state.assessmentStatus = action.payload;
    },
    handleStudentAssessmentData: (state, action) => {
      state.studentAssessmentData = action.payload;
    },
    handleAssessmentById: (state, action) => {
      state.assessmentById = action.payload;
    },
    handleGetAssessment: (state, action) => {
      state.assessmentDetails = action.payload;
    },
    handleGetTaspaAssessment: (state, action) => {
      state.TaspaassessmentDetails = action.payload;
    },
    handleAssessmentCollege: (state, action) => {
      state.AssessmentData = action.payload;
    },
    handleGetCollegeName: (state, action) => {
      state.collegeName = action.payload;
    },
    handleAssessmentQuestion: (state, action) => {
      state.assessmentQuestion = action.payload;
    },
    handleRFiles: (state, action) => {
      state.rFiles = action.payload;
    },
    handleLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

//reducers export  to pass action state to reducer type
export const {
  handleAssessmentDiscipline,
  handleAssessmentStatus,
  handleStudentAssessmentData,
  handleAssessmentById,
  handleAssessmentCollege,
  handleGetAssessment,
  handleGetTaspaAssessment,
  handleLoading,
  handleGetCollegeName,
  handleAssessmentStudent,
  handleAssessmentQuestion,
  handleRFiles,
} = AssessmentSlice.actions;

//action to calls in ui with dispatch methods
export {
  addAssessmentVersion,
  searchAssessment,
  getAssessmentDiscipline,
  addAssessmentDiscipline,
  checkAssessmentStatus,
  takeAssessment,
  getAssessmentById,
  addAssessment,
  getAssessmentData,
  editAssessmentData,
  getAssessmentDetail,
  getTaspaAssessmentDetail,
  addAssessmentData,
  updatePublishStatus,
  updateAdaptivePublishStatus,
  getAssessmentStudent,
  deleteAssessment,
  getAssessmentQuestion,
  viewAssessment,
  addAdaptiveAssessment,
  editAdaptiveAssessment,
  getAdaptiveAssessment,
  addAdaptiveItem,
  editAdaptiveItem,
  deleteAdaptiveItem,
  fileUpload,
  getAdaptiveAssessmentQuestion,
  updateSession,
  editCourseAssessmentData,
  getStudentAssessmentData,
  getAdaptiveAssessmentListWithTestItems,
  saveAdaptiveAssessmentListWithTestItems,
  getAdaptiveTestItems,
  takeTestItems,
  getSelectedAdaptiveAssessmentListWithTestItems,
  getRFiles,
  addCourseAssessSpecificQuestionData,
  getAdaptiveAssessmentQuestionById,
  getAssessmentReports,
  getSelectedAssessmentReports,
};

export default AssessmentSlice.reducer;
