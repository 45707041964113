import { Button, IconButton, Tooltip } from '@mui/material';
import { ICellRendererParams } from 'ag-grid-community';
import { MdModeEdit, MdDelete } from 'react-icons/md';
import { BiCopy } from 'react-icons/bi';
import { FaRegCopy } from 'react-icons/fa';
import FileImport from '../courseDetails/FileImport';
import { AiOutlineFileAdd } from 'react-icons/ai';
import { useState } from 'react';
import { FaUserGraduate } from 'react-icons/fa';

export function Actions(row: ExtendedICellRendererParams) {
  //const {suppliers} = useRoleAuth(); need to uncomment (NTU)
  const [gridParams, setGridParams] = useState<any>(row);
  const [open, setOpen] = useState(false);

  return (
    <div className='flex flex-row gap-1 max-w-xs py-3'>
      <div className='flex flex-col gap-1'>
        <Button
          size='small'
          variant='contained'
          onClick={() => setOpen(true)}
          // startIcon={<FaUserGraduate className='text-edit-icon' />}
        >
          Manage Students
        </Button>

        <FileImport
          props={{
            open: open,
            setOpen: setOpen,
            data: gridParams.data,
            migrated: row.data.migrated,
          }}
        />

        <Button
          size='small'
          variant='contained'
          // startIcon={
          //   <AiOutlineFileAdd
          //     className={'float-right text-blue-600'}
          //     color={row.data.migrated ? 'grey' : ''}
          //   />
          // }
          onClick={() => {
            row.setInitialValue({
              id: '',
              assessment_id: {
                official_name: '',
                assessment_id: '',
                code: '',
              },
              no_of_administrations: 2,
              created_by: '',
            });
            row.getCourseDetails(row, 'paper');
          }}
          disabled={row.data.migrated}
        >
          Add Assessment
        </Button>
      </div>

      <div className='flex flex-col justify-start gap-1'>
        <>
          <IconButton
            size='small'
            color='warning'
            sx={{ padding: 0, margin: 0 }}
            onClick={() => row.handleUpdate(row.data)}
            disabled={row.data.migrated}
          >
            <MdModeEdit
              className='mr-1'
              color={row.data.migrated ? 'grey' : ''}
            />
            <span className='text-black text-sm'> Edit</span>
          </IconButton>
        </>

        <>
          <IconButton
            size='small'
            color='primary'
            onClick={() => row.handleCopy(row.data)}
            sx={{ padding: 0, margin: 0 }}
            disabled={row.data.migrated}
          >
            <FaRegCopy
              className='mr-1'
              color={row.data.migrated ? 'grey' : ''}
            />
            <span className='text-black text-sm'>Copy</span>
          </IconButton>
        </>

        <>
          <IconButton
            color='error'
            size='small'
            onClick={() => row.handleDelete(row.data)}
            sx={{ padding: 0, margin: 0 }}
            disabled={row.data.migrated}
          >
            <MdDelete
              className='mr-1'
              color={row.data.migrated ? 'grey' : ''}
            />
            <span className='text-black text-sm'> Delete</span>
          </IconButton>
        </>
      </div>
    </div>
  );
}

export interface ExtendedICellRendererParams extends ICellRendererParams {
  handleUpdate(data: any): void;
  handleDelete(id: string): void;
  handleCopy(id: string): void;
  getCourseDetails: (
    row: ExtendedICellRendererParams,
    scrollType: string
  ) => void;
  initialValues: any;
  setInitialValue: any;
}

// export const ActionAndImport = (row: ExtendedICellRendererParams) => {
//   // const { data, handleUpdate, handleDelete, handleCopy, getCourseDetails } = params;

//   return (
//     <>
//       <FileImport
//         props={row}
//       />
//       <Actions
//         row={row}
//       />
//     </>
//   );
// };
