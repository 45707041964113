import api from './index';
export interface LoginAppType {
  email: string;
  password: string;
}

const passwordValidation = (data: LoginAppType) =>
  api.post('/password-validation', data);

export { passwordValidation };
