import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { TextField, Box, Button, Popover, MenuItem } from '@mui/material';
import { useState, useEffect } from 'react';

export function MaterialUIPickers({
  index,
  values,
  setValues,
  minDateTime,
  admBtn,
  setAdmBtn,
  disabled,
}: any) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [hours, setHours] = useState('');
  const [minutes, setMinutes] = useState('');
  const [period, setPeriod] = useState('AM');

  const open = Boolean(anchorEl);
  const id = open ? 'picker-popover' : undefined;

  // On initial load, if there's a value, set it. Otherwise, default to current date and time
  useEffect(() => {
    if (values[index] && dayjs(values[index]).isValid()) {
      const currentDate = dayjs(values[index]);
      setSelectedDate(currentDate);
      const currentHours = currentDate.hour() % 12 || 12; // Convert to 12-hour format
      const currentMinutes = currentDate.minute();
      const currentPeriod = currentDate.hour() >= 12 ? 'PM' : 'AM';
      setHours(currentHours.toString());
      setMinutes(currentMinutes.toString().padStart(2, '0'));
      setPeriod(currentPeriod);
    } else {
      // Default to current time if no previous value is set
      const now = dayjs();
      setSelectedDate(now);
      setHours((now.hour() % 12 || 12).toString());
      setMinutes(now.minute().toString().padStart(2, '0'));
      setPeriod(now.hour() >= 12 ? 'PM' : 'AM');
    }
  }, [index, values]);

  const handleOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDoneClick = () => {
    const formattedTime = `${hours.padStart(2, '0')}:${minutes.padStart(
      2,
      '0'
    )} ${period}`;
    const finalDateTime =
      selectedDate.format('YYYY-MM-DD') + ' ' + formattedTime;

    const newValues = [...values];
    newValues[index] = dayjs(finalDateTime).format('YYYY-MM-DDTHH:mm');
    setValues(newValues);

    // Enable admBtn if applicable
    if (admBtn && admBtn.length && setAdmBtn) {
      const newAdmBtnValues = [...admBtn];
      newAdmBtnValues[index] = true;
      setAdmBtn(newAdmBtnValues);
    }

    handleClose();
  };

  const displayValue = values[index]
    ? dayjs(values[index]).isValid()
      ? dayjs(values[index]).format('MM-DD-YYYY hh:mm A')
      : 'MM-DD-YYYY hh:mm'
    : 'MM-DD-YYYY hh:mm';

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {/* Trigger Button to Open Custom Picker */}
      <TextField
        size='small'
        value={displayValue}
        onClick={handleOpen}
        fullWidth
        placeholder='MM-DD-YYYY hh:mm'
        disabled={disabled}
      />

      {/* Popover with Custom Date and Time Picker */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
          {/* Date Picker */}
          <DatePicker
            value={selectedDate}
            minDate={minDateTime ? dayjs(minDateTime) : null}
            onChange={(newDate) => setSelectedDate(newDate || dayjs())}
            renderInput={(params: any) => <TextField {...params} fullWidth />}
          />

          {/* Manual Time Inputs */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <TextField
              label='Hours'
              type='number'
              value={hours}
              onChange={(e) => setHours(e.target.value)}
              inputProps={{ min: 1, max: 12 }}
              sx={{ width: '80px' }}
            />
            :
            <TextField
              label='Minutes'
              type='number'
              value={minutes}
              onChange={(e) => setMinutes(e.target.value)}
              inputProps={{ min: 0, max: 59 }}
              sx={{ width: '80px' }}
            />
            <TextField
              select
              value={period}
              onChange={(e) => setPeriod(e.target.value)}
              sx={{ width: '90px' }}
            >
              <MenuItem value='AM'>AM</MenuItem>
              <MenuItem value='PM'>PM</MenuItem>
            </TextField>
          </Box>

          {/* Done Button */}
          <Button variant='contained' onClick={handleDoneClick}>
            Done
          </Button>
        </Box>
      </Popover>
    </LocalizationProvider>
  );
}
