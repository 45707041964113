import { AppDispatch } from '../Store';
import * as passwordValidationRoute from '../route/passwordValidationRoute';

export interface LoginAppType {
  email: string;
  password: string;
}

const passwordValidation =
  (data: LoginAppType, success: any) => async (dispatch: AppDispatch) => {
    return await passwordValidationRoute
      .passwordValidation(data)
      .then((res: any) => {
        if (res?.data?.message === 'Password is matched') {
          success(true);
        } else {
          success(false);
        }
      })
      .catch(() => {
        success(false);
      });
  };

export { passwordValidation };
