import { Button, TextField } from '@mui/material';
import { useState } from 'react';
import { editCourseAssessmentData } from '../../reduxStore/reducer/assessmentReducer';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../reduxStore/Store';
import { handleUpdateTransaction } from '../../utils/gridMethod/GridTransaction';

const AdmNo = ({
  administrationData,
  setLoading,
  setRefresh,
  noOfAdministration,
  migrated,
}: any) => {
  const dispatch = useDispatch<AppDispatch>();
  const [textValue, setTextValue] = useState<any>(noOfAdministration);
  const [error, setError] = useState('');

  const handleChange = (event: any) => {
    setTextValue(event.target.value);
  };

  const handleUpdateAdministrations = () => {
    if (textValue == '') {
      setError('Required field');
      return; // Prevent update
    }
    const enteredValue = Number(textValue); // Convert input to a number

    // Validation logic
    if (enteredValue > 16 || enteredValue <= noOfAdministration) {
      setError('value must be greater than current value up to 16');
      return; // Prevent update
    } else {
      setError(''); // Clear error if input is valid

      // Prepare the full data with static values and dynamic number of administrations
      const data = {
        ...administrationData, // Include all the static data
        no_of_administrations: enteredValue, // Add the dynamic value
      };

      // Dispatch the action with the updated data
      dispatch(
        editCourseAssessmentData(
          data,
          () => {}, // Handle closing or further actions (optional)
          () => {}, // Any additional callbacks (optional)
          {}, // Params (optional)
          () => {}, // setInitialValue (optional)
          () => {}, // setIsEdit (optional)
          () => {}, // setIsDispatching (optional)
          null, // gridApi if needed
          administrationData, // Initial values for handling updates if needed
          setLoading,
          setRefresh
        )
      );
    }
  };

  return (
    <>
      <TextField
        type='number'
        id='standard-basic'
        variant='standard'
        value={textValue}
        onChange={handleChange}
        error={!!error}
        helperText={error}
        disabled={migrated}
      />
      <Button
        sx={{ textTransform: 'initial' }}
        onClick={handleUpdateAdministrations}
        disabled={migrated}
      >
        Set number of administrations
      </Button>
    </>
  );
};

export default AdmNo;
